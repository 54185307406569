import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { BookDefinition } from '@/components/common/Book';
import { FEATURE_FLAG_KEYS, useFeatureFlagValueByKey } from '@/components/common/FeatureFlag';
import { GridOrderedBookItemRenderer, GridOrderedBookPreset } from '@/components/genreHome/common/GridOrderedBook';
import {
  GridOrderedLayoutSection,
  GridOrderedLayoutSectionContentSkeleton,
} from '@/components/genreHome/common/GridOrderedLayoutSection';
import { SectionLayout } from '@/components/genreHome/common/SectionLayout';
import { useSectionTrackingDataContext } from '@/components/genreHome/common/SectionTrackingDataContextProvider';
import { useAdultInclude } from '@/components/genreHome/hooks/useAdultInclude';
import { BookViewItem, SectionByLayout } from '@/features/genreHome/views/utils/viewDataUtils';
import { fetchSectionItemsResourceAction, sectionItemsByIdSelector } from '@/features/genreHome/views/viewsSlice';
import { currentPathSelector } from '@/features/global/globalNavigationBar/navigation/navigationSlice';
import { RootState } from '@/features/store';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { BestSellerItem, SectionItemFilterTypes, SectionLayoutType } from '@/models/backendsApi/v2/Views/ViewsType';
import { addAdultExcludeBestSellerPathName, isSupportingAdultFilterGenre } from '@/utils/bestSellers';
import { sendClickEvent } from '@/utils/eventClient';

import { AdultFilterToggle } from '../../common/AdultFilterToggle';
import {
  RankingBookItemRenderer,
  RankingBookPreset,
  RankingLayoutSection,
  RankingLayoutSectionContentSkeleton,
} from '../../common/RankingBook';

export interface BestSellerProps {
  section: SectionByLayout<SectionLayoutType.BestSeller>;
}

export const BestSeller = ({ section }: BestSellerProps): ReactJSX.Element => {
  const sectionTrackingData = useSectionTrackingDataContext();
  const dispatch = useAppDispatch();
  const { isFetching } = useSelector((state: RootState) => sectionItemsByIdSelector(state, section.id));

  const currentPath = useSelector(currentPathSelector);
  const showAdultFilterToggle = useMemo(() => {
    let showToggle = false;
    if (
      currentPath !== null &&
      isSupportingAdultFilterGenre({ path: currentPath }) &&
      section.contents.default_filter === SectionItemFilterTypes.ADULT_EXCLUDE
    ) {
      showToggle = true;
    }
    return showToggle;
  }, [currentPath, section.contents.default_filter]);

  const [isDefaultAdultInclude, setDefaultIsAdultInclude] = useAdultInclude();
  const [isAdultInclude, setIsAdultInclude] = useState(
    section.contents.default_filter === SectionItemFilterTypes.ADULT_INCLUDE,
  );

  const endPoint = useMemo(() => {
    const isFetchIncludeAdultContent = !isAdultInclude;
    return isFetchIncludeAdultContent
      ? section.items_filter.adult_include.resource_url
      : section.items_filter.adult_exclude.resource_url;
  }, [
    isAdultInclude,
    section.items_filter.adult_exclude.resource_url,
    section.items_filter.adult_include.resource_url,
  ]);
  const moreDetailLink = useMemo(
    () =>
      currentPath && !isAdultInclude
        ? addAdultExcludeBestSellerPathName(section.more_details.path)
        : section.more_details.path,
    [currentPath, isAdultInclude, section.more_details.path],
  );

  const onAdultFilterToggle = useCallback(
    (bool: boolean) => {
      setIsAdultInclude(bool);
      setDefaultIsAdultInclude(bool);
      dispatch(fetchSectionItemsResourceAction({ reqParams: { sectionId: section.id, endPoint } }));
    },
    [dispatch, endPoint, section.id, setDefaultIsAdultInclude],
  );

  const handleSwitchToggle = useCallback(
    (bool: boolean) => {
      if (!isFetching) {
        sendClickEvent(sectionTrackingData.screenName, 'adult_toggle', {
          ...sectionTrackingData.params,
          status: bool ? 'on' : 'off',
        });

        onAdultFilterToggle(bool);
      }
    },
    [isFetching, onAdultFilterToggle, sectionTrackingData.params, sectionTrackingData.screenName],
  );

  useEffect(() => {
    if (isDefaultAdultInclude && !isAdultInclude) {
      onAdultFilterToggle(true);
    }
  }, [isDefaultAdultInclude, isAdultInclude, onAdultFilterToggle]);

  // 장르홈 랭킹섹션 UI 실험
  const isExperimentTarget = useFeatureFlagValueByKey()(FEATURE_FLAG_KEYS.DISCOVERY_EXP_RANKING_SECTION_20231201);
  const bookPreset = isExperimentTarget ? RankingBookPreset : GridOrderedBookPreset;
  const ItemRenderer = isExperimentTarget ? RankingBookItemRenderer : GridOrderedBookItemRenderer;
  const Skeleton = isExperimentTarget ? RankingLayoutSectionContentSkeleton : GridOrderedLayoutSectionContentSkeleton;
  const render = useCallback(
    ({ item, index }: { item: BookViewItem<BestSellerItem>; index: number }) => (
      <ItemRenderer key={index} item={item} index={index} />
    ),
    [ItemRenderer],
  );
  const sectionRendered = isExperimentTarget ? (
    <RankingLayoutSection items={section.items} renderItem={render} />
  ) : (
    <GridOrderedLayoutSection items={section.items} renderItem={render} />
  );

  return (
    <SectionLayout
      title={section.title}
      link={moreDetailLink}
      horizontal={false}
      sectionHeadChildren={
        showAdultFilterToggle && <AdultFilterToggle checked={isAdultInclude} onChange={handleSwitchToggle} />
      }>
      {isFetching ? (
        <Skeleton />
      ) : (
        <BookDefinition presets={bookPreset} trackingData={sectionTrackingData.bookDefinition}>
          {sectionRendered}
        </BookDefinition>
      )}
    </SectionLayout>
  );
};
