import {
  BestSellersQuery,
  RecommendBestSellersQuery,
  useBestSellersQuery,
  useRecommendBestSellersQuery,
} from '@ridi-web/gql-client-codegen';
import { QueryClient, useQueries, UseQueryOptions } from 'react-query';

import { GenreType } from '@/base/constants/genreMap';

import { convertQueryParamsToVariables } from '../converter';
import { ExactBestsellersQueryVariables, QueryParams } from '../types';
import {
  getRecommendBestSellerVariables,
  isInvalidRecommendBestSellerQuery,
  isInvalidRecommendBestSellerVariable,
} from './business';

type ServerSideFetchBestSellerParams = {
  queryClient: QueryClient;
  queryParams: QueryParams;
};

const getPreFetchRecommendBestSeller = ({ queryClient, queryParams }: ServerSideFetchBestSellerParams) => {
  let fetchFunc: () => Promise<null | void>;
  if (isInvalidRecommendBestSellerQuery(queryParams.query)) {
    fetchFunc = () => Promise.resolve(null);
  } else {
    const variables = getRecommendBestSellerVariables(queryParams.params.genre);
    fetchFunc = () =>
      queryClient.prefetchQuery(
        useRecommendBestSellersQuery.getKey(variables),
        useRecommendBestSellersQuery.fetcher(variables),
      );
  }
  return fetchFunc;
};

export const serverSideFetchBestSeller = ({ queryClient, queryParams }: ServerSideFetchBestSellerParams) => {
  const preFetchRecommendBestSeller = getPreFetchRecommendBestSeller({ queryClient, queryParams });
  const variables = convertQueryParamsToVariables(queryParams);

  return Promise.all([
    queryClient.fetchQuery(useBestSellersQuery.getKey(variables), useBestSellersQuery.fetcher(variables)),
    preFetchRecommendBestSeller(),
  ]);
};

const getClientSideRecommendBestSellerFetch = (variables: ExactBestsellersQueryVariables) => {
  let queryFn;
  if (isInvalidRecommendBestSellerVariable(variables)) {
    queryFn = () => Promise.resolve({ bestsellers: { recommendItem: undefined } } satisfies RecommendBestSellersQuery);
  } else {
    queryFn = useRecommendBestSellersQuery.fetcher(variables);
  }
  return queryFn;
};

export const useBestSellerQueries = ({
  variables,
  genre,
}: {
  variables: ExactBestsellersQueryVariables;
  genre: GenreType;
}) => {
  const recommendBestSellerVariables = getRecommendBestSellerVariables(genre);

  return useQueries<[UseQueryOptions<BestSellersQuery>, UseQueryOptions<RecommendBestSellersQuery>]>([
    { queryKey: useBestSellersQuery.getKey(variables), queryFn: useBestSellersQuery.fetcher(variables) },
    {
      queryKey: useRecommendBestSellersQuery.getKey(recommendBestSellerVariables),
      queryFn: getClientSideRecommendBestSellerFetch(variables),
    },
  ]);
};

export const tests__Fetch = { getPreFetchRecommendBestSeller, getClientSideRecommendBestSellerFetch };
